<script>
import {
  ArrowUpIcon,
  ArrowRightIcon,
  ArrowRightCircleIcon,
  HelpCircleIcon,
  PhoneIcon,
  VideoIcon,
} from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
import Pricing from "@/components/pricing";
import countTo from "vue-count-to";
import { Carousel, Slide } from "vue-carousel";


{
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: 'Parent description.'
      }
    ]
  }
}


/**
 * Index task-management component
 */


export default {
  data() {
    return {
    }
  },
  components: {
    Navbar,
    Carousel,
    Slide,
    Footer,
    PhoneIcon,
    Pricing,
    ArrowUpIcon,
    ArrowRightIcon,
    ArrowRightCircleIcon,
    HelpCircleIcon,
    VideoIcon,
    countTo,
  },


      metaInfo() {
        return { 
            title: "Karukaj Members Portal : Build Network in your field of interest",
            meta: [
                { name: 'description', content:  '"KD Members Portal" is the vitual headquarters of Karukaj DIGITAL, where "KD Squad" conducts simple and artistic activities to known as the best digital marketing agency in Bangladesh.'},
                { property: 'og:title', content: "Karukaj - Build your network in your field of interest"},
                { property: 'og:site_name', content: 'Epiloge'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    }
};




</script>





<template>
 
 
  <div>
    <Navbar :nav-light="true" />
    <!-- Start Home -->


    <!-- Hero Start -->
    <section class="bg-half-260 multi-color-bg d-table w-100" style="background: url('images/background/21647836.jpg') center bottom">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="my-custom-title2">
                        <h4 class=" text-white title-dark mb-4">
                    
                          
                          
                        Get our digital marketing agency<br>anytime and anywhere</h4>
                        <div class="pt-2">
           




                           <div class="alert alert-outline-primary alert-pills" role="alert">
<span class="badge badge-pill badge-danger">  </span>   <span class="alert-content font-weight-bold ">WE OFFER ORGANIZED WORK ONLINE</span>
</div>


                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!-- Hero End -->

   <section class="section">


 


      <div class="container pt-4 mt-100 mt-60">
        <div class="row justify-content-center" id="counter">
          <div class="col-12 text-center">
            <div class="section-title">
              

              <h3 class="responsive-medium title">Let’s Get Some Real Info<span v-b-tooltip.hover title="Karukaj DIGITAL is one of the best digital marketing agency in Bangladesh near Dhaka, 
                          where Karukaj Business CRM is the digital agency management area of ' Karukaj Digital ' to offer digital marketing service and handle full operations. 
                Sign up and check our best website design price in bangladesh" class="my-color-1 font-weight-bold">*</span>  Here</h3>
              <p class="responsive-small text-muted para-desc mx-auto mb-0">
              hire our creative artists and increase your brand exposure;<br/>people will recognize your best image.
              </p>


            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->


















      



      <div class="responsive-center container">
        <br>
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 order-1 order-md-2">
            <img src="images/mysvg/task-manage.svg" class="img-fluid mover" alt="" />
          </div>
          <!--end col-->
          <div
            class="col-lg-6 col-md-6 mt-sm-0 pt-sm-0 order-2 order-md-1"
          >
            <div class="section-title mr-lg-5">
               <img src="images/icon8/idea_80px.png" class="avatar avatar-80x" alt="">

              <h4 class="title mb-4"><span v-b-tooltip.hover title="best-experienced website development company 
                  or digital marketing company, where we come up with creative freelance 
                  web developer skill and expert digital marketing service " class="my-color-1 font-weight-bold">Plan and Collaborate </span>
                <br />
                Your Content
              </h4>
              <p class="responsive-small text-muted">
                After collaborating on minimum advance payments with the plan & necessary content, one of our representatives will update your existing profile on our 
                <span v-b-tooltip.hover title="Karukaj DIGITAL is one of the best digital marketing agency in Bangladesh near Dhaka, 
                          where KD Members Portal is the digital agency management area of ' KD ' to offer digital marketing service and handle full operations. Get Started." class="text-muted">CRM Business Portal</span> and give you access to manage new tasks or project activities.
              </p>


                     



            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <br>

            <div class="responsive-center container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6">
            <img  width="60%" height="200" 
              src="images/mysvg/notificarions.svg"
              class="img-fluid rounded-md"
              alt=""
            />


          </div>
          <!--end col-->

          <div class="col-lg-6 col-md-6 mt-sm-0 pt-sm-0">
            <div class="section-title ml-lg-5">
               <img src="images/icon8/hongkong_dollar_80px.png" class="avatar avatar-80x" alt="">
              <h4 class="title mb-4">Get instant Updates <br>via SMS and WhatsApp</h4>
              <p class="responsive-small text-muted">                
We've integrated instant SMS and WhatsApp notification system in our business portal to 
maintain transparency and speed up the progress of the work.
              </p>

              <p class="mt-3 h6 my-color-1 font-weight-bold"
                >How does it 
<span v-b-tooltip.hover title="Best-experienced website development company 
                  or digital marketing company, where we come up with creative freelance 
                  web developer skill and expert digital marketing service.">work directly?</span></p>


              <ul class="responsive-small list-unstyled text-muted">
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm my-color-1 mr-2"
                  ></arrow-right-circle-icon
                  >To inform our team members about new tasks and info
                </li>
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm my-color-1 mr-2"
                  ></arrow-right-circle-icon
                  >To inform our client about the progress, including the bill
                </li>
              </ul>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->



















    </section>
    <!--end section-->











   <section class="section">












    <div class="container">
        <div class="row justify-content-center" id="counter">
          <div class="col-12 text-center">
            <div class="section-title">
              














              <div class="watch-video">

<div
                      class="alert alert-light alert-pills shadow"
                      role="alert"
                    >
                      <span class="content">
                        So, are you ready to hire us?</span
                      >
                    </div>

                <a
                  href="javascript: void(0);"
                  v-b-modal.modal
                  class="btn btn-icon btn-pills video-play-icon btn-primary m-1"
                  >
                  <video-icon class="icons"></video-icon>
                  </a
                >
                <span class="font-weight-bold text-uppercase small align-middle ml-1"></span>
              </div>
              <b-modal
                id="modal"
                hide-footer
                size="lg"
                header-close-variant="white"
                header-class="border-0"
                content-class="border-0"
                centered
              >
                <youtube
                  video-id="d4aijRJFOq4"
                  ref="youtube"
                  width="100%"
                  height="432"
                  :player-vars="playerVars"
                ></youtube>
              </b-modal>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->













      <div class="container ">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title ">
              <h4 class="title">
                charge your creative inspiration
              </h4>
              <p class="text-muted para-desc mx-auto mb-0">
                We pursue relationships based on transparency, persistence, mutual trust, and integrity with our employees, customers and other business partners.
              
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row align-items-center">
          <div class="col-12">
            <div class="">
              <b-tabs
                pills
                justified
                nav-class="d-inline-block border py-1 px-2 rounded-pill"
                nav-wrapper-class="text-center"
                align="center"
              >
                <b-tab
                  title-item-class="d-inline-block"
                  title-link-class="px-3 rounded-pill"
                >
                  <template v-slot:title> Monthly Plan </template>


        <div class="row mt-lg-4 align-items-center">
          <div class="responsive-hide col-lg-5 col-md-12 text-center text-lg-left">
            <div class="section-title mb-4 mb-lg-0 pb-2 pb-lg-0">





         <div class="p-4">
          <br><br> <img  width="auto" height="400" 

src="images/mysvg/pricing.svg"

class="img-fluid mover"
alt=""
/>


<h6 class="my-color-1 text-center ">UNLOCK KNOWLEDGEBASE <i class="mdi mdi-chevron-right"></i></h6>

<br>

         <p class="text-center black-color-font">"Money can’t buy time; <br/>unless you’ve got plan" </p>



                      </div>
























            </div>
          </div>
          <!--end col-->

          <div class="col-lg-7 col-md-12">
            <div class="ml-lg-5">
              <div class="row align-items-center">

                <div class="col-md-6 col-12 mt-4 pt-2 pt-md-4 px-md-0">
                  <div class="card pricing-rates bg-light shadow rounded border-0">
                    <div class="card-body py-5">
                      <h6 class="title font-weight-bold text-uppercase my-color-1 mb-4" >
                        Professional
                      </h6>
                       <p class="h6 bdprice">BDT 25000/-</p>

                      <div class="d-flex mb-4">

                        <span class="h4 mb-0 mt-2 my-color-1">$</span>
                        <span class="price h1 mb-0">299</span>
                        <span class="h4 align-self-end mb-1">/mo</span>
                      </div>

                      <ul class="list-unstyled mb-0 pl-0">
                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >Any 10 days
                        </li>
                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >2 hours block/day
                        </li>
                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >1/2/7 task equivalent
                        </li>

                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >Premium access
                        </li>
                      </ul>




                      <a href="javascript: void(0);" class="btn btn-primary mt-4" v-b-modal.modal-package1>Get Started</a>


                      <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-package1" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
       
          <div class="col-lg-6 col-md-6 order-1 order-md-1">
          <img height="420" width="420" src="images/marketing/marketing.png" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-2">
          <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/9c7ff50000f43b5e721aa6e1913d2bc7"  style="border:0"></iframe>
        </div>

        </div>
        </b-modal>





                    </div>
                  </div>
                </div>
                <!--end col-->


                <div class="col-md-6 col-12 mt-4 pt-4 px-md-0">
                  <div
                    class="card pricing-rates starter-plan shadow rounded border-0"
                  >
                    <div class="card-body py-5">
                      <h6
                        class="title font-weight-bold text-uppercase my-color-1 mb-4"
                      >
                        Premium Plan

                      </h6>
                      <p class="h6 bdprice">BDT 48000/-</p>

                      <div class="d-flex mb-4">
                        <span class="h4 mb-0 mt-2">$</span>
                        <span class="price h1 mb-0">499</span>
                        <span class="h4 align-self-end mb-1">/mo</span>
                      </div>

                      <ul class="list-unstyled mb-0 pl-0">
                         <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >Any 20 days
                        </li>
                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >3 hours block/day
                        </li>
                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >1/5/10 task equivalent
                        </li>
                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >within 45 days
                        </li>
                         <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >(Per Session) Payrolls
                        </li>
                      </ul>





                      <a href="javascript: void(0);" class="btn btn-primary mt-4" v-b-modal.modal-package2>Get Started</a>


                      <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-package2" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
       
          <div class="col-lg-6 col-md-6 order-1 order-md-1">
            
          <img height="420" width="420" src="images/marketing/marketing.png" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-2">
          <iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/9c7ff50000f43b5e721aa6e1913d2bc7"  style="border:0"></iframe>
        </div>

        </div>
        </b-modal>










                    </div>
                  </div>
                </div>
                <!--end col-->

              </div>
              <!--end row-->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->






















                </b-tab>
                <b-tab
                  title-item-class="d-inline-block"
                  title-link-class="px-3 rounded-pill"
                >
                  <template v-slot:title>
                    Daily Plan
                  </template>
             









                 <br>
                 <br>
                  <div class="row">
                    <div class="responsive-hide col-lg-3 col-md-6 col-12 mt-4 pt-2">
                      


                      <div class="pricing-rates bg-light py-5 p-4 rounded">

                        <h6  v-b-tooltip.hover title="Maintenance Purpose or for Quick Planner" class="my-color-1">DAILY PLAN <i class="mdi mdi-chevron-right"></i></h6>

                       
                        


           



                        <p class="responsive-small text-muted para-desc mb-0 mx-auto">
                At <span v-b-tooltip.hover title="With other digital marketing service, our website design price in Bangladesh is very affordable.
                  In addition to custom redesigning old websites, Karukaj digital marketing agency is one of the 
                  best web development companies in Bangladesh." class=" text-muted">Karukaj DIGITAL</span>
                  we work in phases, not on the basis of contract, but on the basis of advance payment or full payment. 
                  Flexible and comfortable price list is just for you in monthly plan with well-documentation. 
             
                
                </p>

        
                 


                      </div>






























                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                      <div
                        class="card pricing-rates starter-plan bg-white shadow rounded border-0"
                      >
                        <div class="card-body py-5">
                          <h6
                            class="title text-uppercase font-weight-bold my-color-1 mb-4"
                          >
                            Pico Package
                          </h6>
                          <p class="h6 bdprice">BDT 1000/-</p>

                          <div class="d-flex mb-4">
                            <span class="h4 mb-0 mt-2">$</span>
                            <span class="price h1 mb-0">10</span>
                            <span class="h4 align-self-end mb-1"> / task</span>
                          </div>

                          <ul class="list-unstyled mb-0 pl-0">
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >One single task
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >1 day 2 hours
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >Free consultation
                            </li>
                          </ul>






                          <a href="javascript: void(0);" class="btn btn-primary mt-4" v-b-modal.modal-package3>Try us Now</a>


<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-package3" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">

<div class="col-lg-6 col-md-6 order-1 order-md-1">

<img height="420" width="420" src="images/marketing/marketing.png" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-2">
<iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/9c7ff50000f43b5e721aa6e1913d2bc7"  style="border:0"></iframe>
</div>

</div>
</b-modal>






                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                      <div class="card pricing-rates bg-light rounded border-0">
                        <div class="card-body py-5">
                          <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span class="text-center d-block shadow small h6">Best</span></div>
                          <h6
                            class="title text-uppercase font-weight-bold mb-4"
                          >
                            Micro Package
                          </h6>
                          <p class="h6 bdprice">BDT 6000/-</p>
                          <div class="d-flex mb-4">
                           <span class="h4 mb-0 mt-2">$</span>
                            <span class="price h1 mb-0">67</span>
                            <span class="h4 align-self-end mb-1">/ mo</span><br>
                          

                            

                          </div>

                          <ul class="list-unstyled mb-0 pl-0">
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >Any 4 days plan
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >1/5 task equivalent
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >2 hours block/day
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >Premium resource
                            </li>
                          </ul>









                          <a href="javascript: void(0);" class="btn btn-primary mt-4" v-b-modal.modal-package4>Get Started</a>


<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-package4" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">

<div class="col-lg-6 col-md-6 order-1 order-md-1">

<img height="420" width="420" src="images/marketing/marketing.png" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-2">
<iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/9c7ff50000f43b5e721aa6e1913d2bc7"  style="border:0"></iframe>
</div>

</div>
</b-modal>
















         
                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                      <div class="card pricing-rates bg-light rounded border-0">
                        <div class="card-body py-5">
                          <h6
                            class="title text-uppercase font-weight-bold mb-4"
                          >
                            Meta Package
                          </h6>
                          <p class="h6 bdprice">BDT 10,000/-</p>

                          <div class="d-flex mb-4">
                            <span class="h4 mb-0 mt-2">$</span>
                            <span class="price h1 mb-0">129</span>
                            <span class="h4 align-self-end mb-1">/mo</span>
                          </div>

                          <ul class="list-unstyled mb-0 pl-0">
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >Any 7 days plan
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >1/9 task equivalent
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >2 hours block/day
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >Instant metting
                            </li>
                                                        <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >SOP Premium Access
                            </li>
                          </ul>




                          <a href="javascript: void(0);" class="btn btn-primary mt-4" v-b-modal.modal-package5>Get Started</a>


<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-package5" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">

<div class="col-lg-6 col-md-6 order-1 order-md-1">

<img height="420" width="420" src="images/marketing/marketing.png" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-2">
<iframe height="420" width="380" frameborder="0" src="https://members.karukaj.net/forms/wtl/9c7ff50000f43b5e721aa6e1913d2bc7"  style="border:0"></iframe>
</div>

</div>
</b-modal>






                        </div>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->












                </b-tab>
              </b-tabs>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->





      <div class="container mt-100 mt-60">


        <div class="row">
          <div class="col-md-6 col-12">
            <div class="media">
              <help-circle-icon
                class="fea icon-ex-md my-color-1 mr-2 mt-1"
              ></help-circle-icon>
              <div class="media-body">








                <h5 class="mt-0">How to create Karukaj account?</h5>
                <p class="responsive-small answer text-muted mb-0">
                  It's like opening any email account. That means your name, email, phone number, business address, type of business, etc. 

             

                </p>


              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="media">
              <help-circle-icon
                class="fea icon-ex-md my-color-1 mr-2 mt-1"
              ></help-circle-icon>
              <div class="media-body">



                  
   
                <h5 class="mt-0">
                  How do Karukaj exchange files?
                </h5>
                <p class="responsive-small answer text-muted mb-0">
                We strongly recommend to transfers file through Google Drive. However, within the <span v-b-tooltip.hover title="Karukaj DIGITAL is one of the best digital marketing agency in Bangladesh near Dhaka, 
                          where Business CRM is the digital agency management area of ' Karukaj DIGITAL' to offer digital marketing service and handle full operations. Get Started." class="text-muted"> Business CRM </span>, you will find certain types of file sharing options in all available places.
                </p>             
       
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12 mt-4 pt-2">
            <div class="media">
              <help-circle-icon
                class="fea icon-ex-md my-color-1 mr-2 mt-1"
              ></help-circle-icon>
              <div class="media-body">


  <h5 class="mt-0">


                  Why do you need a Karukaj account?
                </h5>

   
                <p class="responsive-small answer text-muted mb-0">
<span v-b-tooltip.hover title="Karukaj DIGITAL is one of the best digital marketing agency in Bangladesh near Dhaka, 
                          where Karukaj Business CRM is the digital agency management area of ' Karukaj DIGITAL' to offer digital marketing service and handle full operations. Get Started.">"Karukaj Business CRM"</span>
                          is used to complete each task/project in few steps, which is easier for both developers and customers. We use this management system as a tool to plan, follow up and ensure that the business is run according to established routines.</p>

            

        
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12 mt-4 pt-2">
            <div class="media">
              <help-circle-icon
                class="fea icon-ex-md my-color-1 mr-2 mt-1"
              ></help-circle-icon>
              <div class="media-body">
                <h5 class="mt-0">Is it possible to get service without an account?</h5>
                <p class="responsive-small answer text-muted mb-0">
                  Yes. But this may be necessary. we save development-related information in your account for future reference. You may also need such information, 
                  which you can get by logging in or reset by email at any time. So you can be safe and secure with our purpose and your participation.

                  <span v-b-tooltip.hover title="Karukaj does outsourcing in Bangladesh. Besights, Karukaj is the most popular as the best e-commerce website development company in Bangladesh, who have any kind of custom skills as a web development company in Bangladesh. Stay around us!
                  " class="text-muted">Karukaj DIGITAL</span>                  As this may be necessary, 
                </p>

              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->


      </div>
      <!--end container-->



















      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="section-title text-center mb-4 pb-2">
              <h4 class="title">


                Discover our
                <span v-b-tooltip.hover title="Business.Karukaj Portal is an expert digital marketing agency CRM management area of Karukaj DIGITAL, from which
we manage the full digital marketing service list, create billing info and collect payment. Stay around us!" class="my-color-1 font-weight-bold">Business CRM</span>  Management System


 
              </h4>

              
              <p class="text-muted para-desc mb-0 mx-auto">
                
                A better way to manage your task one by one. More than just a place to work
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
<br><br>
        <div class="row align-items-center">
          <b-tabs
            pills
            justified
            vertical
            nav-wrapper-class="col-md-5"
            nav-class="rounded-md"
            content-class="col-md-7 col-12"
          >






            <b-tab active>
              <template v-slot:title>
                <div class="p-3 text-left">
                  <h5 class="title">Realtime Project Management</h5>
                  <p class="responsive-small text-muted tab-para mb-0">
                    Your digital marketing service requirements will vary, so each of our task costs will be handled differently for our digital assistants.
                                              

                  </p>
                </div>
              </template>


              <img  width="auto" height="400" 

              src="images/mysvg/planning.svg"
      
              class="img-fluid rounded-md"
              alt=""
            />


    
            </b-tab>


            <b-tab>
              <template v-slot:title>
                <div class="p-3 text-left">
                  <h5 class="title">Task or Support Tickets Area</h5>
                  <p class="responsive-small text-muted tab-para mb-0">
                    <p class="responsive-small text-muted mb-0">

<span v-b-tooltip.hover title="Karukaj DIGITAL is one of the best digital marketing agency in Bangladesh near Dhaka, 
                          where KD Members Portal is the digital agency management area of ' KD ' to offer digital marketing service and handle full operations. Get Started." class="text-muted">Karukaj Business CRM</span>, Your digital marketing service requirements will vary, so each of our task costs will be handled differently for our digital assistants.</p>
                  </p>
                </div>
              </template>

              <img  width="auto" height="400" 
              src="images/mysvg/Business-Planning.svg"
              class="img-fluid rounded-md"
              alt=""
            />


            </b-tab>


            <b-tab>
              <template v-slot:title>
                <div class="p-3 text-left">
                  <h5 class="title">Record Payment and Expense</h5>
                  <p class="responsive-small text-muted tab-para mb-0">
                    Automatically track expenses and payments updates with local and international payment systems.

                  </p>
                </div>
              </template>
              
              <img
                src="images/mysvg/alarm-for-time-management.png"
                class="img-fluid mx-auto rounded-md d-block"
                alt=""
              />
            </b-tab>

     
            <b-tab>
              <template v-slot:title>
                <div class="p-3 text-left">
                  <h5 class="title">KnowledgeBase with Chat</h5>
                  <p class="responsive-small text-muted tab-para mb-0">

                    Members will get a 24/5 Chat facility, in addition to a free KnowledgeBase facility worth more than 500+ $.
                  </p>
                </div>
              </template>
              <img
                src="images/illustrator/SEO_SVG.svg"
                class="img-fluid mx-auto rounded-md d-block"
                alt=""
              />
            </b-tab>
          </b-tabs>
        </div>
        <!--end row-->
      </div>
      <!--end container-->














<div class="container mt-100">
  <div class="row justify-content-center" id="counter">
    <div class="col-12 text-center">
      <div class="section-title">
        <h4 class="mb-0">
          Actions speak louder than words! 
            </h4>

        <p class="text-muted mb-0 mb-4 mx-auto para-desc">
          Save your time to get it up and see everything OnBoard
        </p>
    
        







      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>
<!--end container-->

























        <div class="container mt-40">
            <div class="row align-items-center">




              <div class="col-lg-5 col-md-6 md-4 col-12 mt-sm-0 pt-2 pt-sm-0">






              <p class="text-muted mb-0">
                  <span v-b-tooltip.hover title="Karukaj does outsourcing in Bangladesh. Besights, Karukaj is the most popular as the best e-commerce website development company in Bangladesh, who have any kind of custom skills as a web development company in Bangladesh. Stay around us!
                  " class="text-muted">Karukaj DIGITAL</span>, another best digital marketing agency, that has been providing digital marketing service since 2007 
                  and will continue to do so in the future. We believe that establishing core values ​​like honesty, openness, 
                  and fairness is the key to building a transparent organization. </p>



              <div class="carousel-cell">
                <img 
                  src="images/mysvg/Business-Planning.svg"
                  class="img-fluid mover"
                  alt=""
                />
              </div>

          <!--end col-->
                

          <h4 class="title font-weight-bold">Are you new to us?            
              </h4>
          <p class="text-muted mx-auto mb-4">
                Let us know by filling out this simple form.
                A dedicated expert from our system will reply you or inform you about project estimate with details. 
              </p>

              <p class="text-muted font-weight-bold mb-4">You can also chat with us via our favorite channels.</p>






              <div class="media contact-detail align-items-center mt-3">
                <div class="icon">
                  <i class="uil uil-facebook-messenger-alt icon-m-md text-dark mr-3 icon-size2 font-weight-bold"></i>
                </div>
                <div class="media-body content">
                  <h5 class="title font-weight-bold mb-0">Messenger</h5>
                  <a  target="_blank" href="https://fb.me/karukaj.digital" class="my-color-1"
                    >fb.me/karukaj.digital</a>
              </div>
            </div>

                





            <div class="media contact-detail align-items-center mt-3">
                <div class="icon">
                  <i class="uil uil-whatsapp icon-m-md text-dark mr-3 icon-size2 font-weight-bold"></i>
                </div>
                <div class="media-body content">
                  <h5 class="title font-weight-bold mb-0">WhatsApp</h5>
                  <a  target="_blank" href="https://wa.me/8801714641541?text=Hi%20there!" class="my-color-1"
                    >wa.me/8801714641541</a>
              </div>
            </div>




            <div class="my-color-2"><small>24 hours. That's all it will take you to figure out the cost benefits</small></div>



            

                </div>






                <div class="col-lg-7 col-md-6 col-12">
                  <div class="card rounded bg-light shadow-lg border-0">
          <div class="card-body">

            <div class="title-heading">


              <h4 class="text-center font-weight-bold my-color-1">
                Get a free Price Estimate
            </h4>
             
               <p class="text-center font-weight-bold">
                You are just a few clicks away to get reply from us
               </p>
                      </div>      
                      
                      
                      <iframe width="100%" height="640" src="https://members.karukaj.net/forms/quote/45e759339042747f47df23db4d26987c" frameborder="0" allowfullscreen></iframe>



                    </div>       
                    
                    

                    </div>



                </div>

          





            </div>
        </div>
        <!--end container-->







































    </section>
    <!--end section-->
    <!-- Features End -->
    <!--end section-->

















    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-light btn-icon back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>






